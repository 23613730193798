<!--
 * @Description: 
 * @Author: transparent
 * @Date: 2022-03-15 15:21:18
 * @LastEditTime: 2022-06-20 11:41:18
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/project/projectDetails.vue
-->
<template>
  <div class="details" v-loading="loading">
    <div v-show="!loading">
      <div class="title">
        <p>{{ detail.name }}</p>
      </div>
      <el-form>
        <el-row type="flex" justify="end">
          <el-form-item v-if="detail.navButton != null">
            <el-button-group class="pr-5">
              <el-button
                type="primary"
                icon="el-icon-arrow-left"
                @click="changeProject('previous')"
                :disabled="detail.navButton.prevId == null"
                >上一项目</el-button
              >
              <el-button
                type="primary"
                @click="changeProject('next')"
                :disabled="detail.navButton.nextId == null"
                >下一项目<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <el-button
              :type="detail.isFavorite == 1 ? '' : 'primary'"
              @click="followProject"
              >{{ detail.isFavorite == 1 ? "取消关注" : "关注" }}</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>

      <el-tabs
        type="border-card"
        v-model="detailActiveName"
        @tab-click="tabHandleClick"
        v-show="!loading"
      >
        <el-tab-pane label="项目基本信息">
          <template v-if="dataAuth.baseInfo">
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>项目名：</b>
                <span>{{ detail.name }}</span>
              </li>
              <li>
                <b>公司名称：</b>
                <span>{{ detail.principalName }}</span>
              </li>
              <li class="width100">
                <b>行业：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.industryList"
                  :key="index"
                >
                  <div v-if="detail.industryList.length == 1">
                    {{ item.industry
                    }}<span v-show="item.subIndustry">
                      - {{ item.subIndustry }}</span
                    >
                  </div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item.industry
                    }}<span v-show="item.subIndustry">
                      - {{ item.subIndustry }}</span
                    >
                  </div>
                </div>
              </li>
              <li>
                <b>模式：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.modeList"
                  :key="index"
                >
                  <div v-if="detail.modeList.length == 1">{{ item.name }}</div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item.name }}
                  </div>
                </div>
              </li>
              <li>
                <b>一句话描述：</b>
                <span>{{ detail.brief }}</span>
              </li>
              <li class="width100">
                <b>投资机构：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.orgList"
                  :key="index"
                >
                  <div v-if="detail.orgList.length == 1">
                    {{ item.organizationName }}
                  </div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item.organizationName }}
                  </div>
                </div>
              </li>
              <li>
                <b>网店：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.onlineShopList"
                  :key="index"
                >
                  <div v-if="detail.onlineShopList.length == 1">
                    {{ item.name }}（{{
                      item.type == 1 ? "淘宝" : item.type == 2 ? "京东" : ""
                    }}）
                  </div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item.name }}（{{
                      item.type == 1 ? "淘宝" : item.type == 2 ? "京东" : ""
                    }}）
                  </div>
                </div>
              </li>
              <li v-if="!loading && detail.principalLocation">
                <b>所在城市：</b>
                <span>{{
                  detail.principalLocation.province +
                  detail.principalLocation.city
                }}</span>
              </li>
              <li>
                <b>营收规模：</b>
                <span>{{ detail.revenueScaleName }}</span>
              </li>
              <li class="width100">
                <b>品牌：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.brand"
                  :key="index"
                >
                  <div v-if="detail.brand.length == 1">{{ item }}</div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item }}
                  </div>
                </div>
              </li>
              <li class="width100">
                <b>搜索关键字：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.searchKeywords"
                  :key="index"
                >
                  <div v-if="detail.searchKeywords.length == 1">{{ item }}</div>
                  <div v-else>
                    <span class="forIndex">{{ index + 1 }}</span
                    >. {{ item }}
                  </div>
                </div>
              </li>

              <!--<li>
        <b>项目批准（立项）时间:</b>
        <span>{{ detail.approvalTime | formatDateFilter }}</span>
      </li>
      <li>
        <b>合作开始时间:</b>
        <span>{{ detail.cooperateBeginTime | formatDateFilter }}</span>
      </li>
      <li>
        <b> 计划完成时间:</b>
        <span>{{ detail.planFinishTime | formatDateFilter }}</span>
      </li>
      <li>
        <b>投融资需求：</b>
        <span>{{ detail.investDemand }}</span>
      </li>
      <li>
        <b>投融资进展：</b>
        <span>{{ detail.investProgress }}</span>
      </li> -->
              <li class="width100">
                <b>项目简介：</b>
                <span class="lineFeed">{{ detail.intro }}</span>
              </li>

              <li class="width100">
                <b>项目亮点：</b>
                <span class="lineFeed">{{ detail.shiningPoint }}</span>
              </li>
              <li class="width100">
                <b>融资情况：</b>
                <span class="lineFeed">{{ detail.financeInfo }}</span>
              </li>
              <li class="width100">
                <b>团队履历：</b>
                <span class="lineFeed">{{ detail.teamResume }}</span>
              </li>

              <li>
                <b>投资主体：</b>
                <span>{{ detail.investorPrincipalName }}</span>
              </li>
              <li>
                <b>成立时间：</b>
                <span>{{ detail.principalCreateTime | formatDateFilter }}</span>
              </li>
              <li class="width100">
                <b>项目备注：</b>
                <span class="lineFeed">{{ detail.remark }}</span>
              </li>
              <li>
                <b>项目创建时间：</b>
                <span>{{ detail.createDate | formatDateFilter }}</span>
              </li>
            </ul>
          </template>
          <template v-else-if="dataAuth.baseInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply :info="dataAuthInfo.baseInfo" />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目工商信息">
          <template v-if="dataAuth.principalRegInfo">
            <template slot="title">
              项目工商信息<i class="header-icon el-icon-info"></i>
            </template>
            <!-- <div class="flexJfAc" v-show="principalRegInfoNameLink">
            <h5>去企查查看更多信息？：</h5>
            <el-link :href="principalRegInfoNameLink" target="_blank">{{
              detail.principalRegInfo.name
            }}</el-link>
          </div> -->
            <ul
              v-if="detail.principalRegInfo != null"
              class="bar"
              style="list-style-type: none"
            >
              <li>
                <b>公司名：</b>
                <span class="flexJfAc">
                  <div class="name">{{ detail.principalRegInfo.name }}</div>
                  <el-link
                    type="primary"
                    :href="principalRegInfoNameLink"
                    target="_blank"
                    >去企查查看更多信息？</el-link
                  >
                </span>
              </li>
              <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
              <li>
                <b>法人：</b>
                <span> {{ detail.principalRegInfo.legalPersonName }}</span>
              </li>
              <li>
                <b>公司logo:</b>
                <img
                  :src="detail.principalRegInfo.logo"
                  width="80px"
                  height="80px"
                  alt
                />
              </li>

              <li>
                <b>注册资本：</b>
                <span>{{ detail.principalRegInfo.regCapital }}</span>
              </li>
              <li>
                <b>注册时间：</b>
                <span>
                  {{
                    detail.principalRegInfo.fromTime | formatDateFilter
                  }}</span
                >

                <!-- {{detail.fromTime}} -->
              </li>
              <li>
                <b>统一信用代码:</b>
                <span>{{ detail.principalRegInfo.creditCode }}</span>
              </li>
              <li>
                <b>企业类型:</b>
                <span> {{ detail.principalRegInfo.companyOrgType }}</span>
              </li>

              <li>
                <b>工商注册号:</b>
                <span> {{ detail.principalRegInfo.regNumber }}</span>
              </li>
              <li>
                <b>组织机构代码:</b>
                <span>{{ detail.principalRegInfo.orgNumber }}</span>
              </li>
              <li>
                <b>营业期限:</b>
                <span>
                  {{ detail.principalRegInfo.toTime | formatDateFilter }}</span
                >
              </li>
              <li>
                <b>行业:</b>
                <span> {{ detail.principalRegInfo.industry }}</span>
              </li>
              <li class="width100">
                <b>经营范围:</b>
                <span>{{ detail.principalRegInfo.businessScope }}</span>
              </li>
              <li>
                <b>注册地址:</b>
                <span>{{ detail.principalRegInfo.regLocation }}</span>
              </li>
              <li>
                <b>联系电话:</b>
                <span>{{ detail.principalRegInfo.phoneNumber }}</span>
              </li>
              <li>
                <b>联系电话:</b>
                <span>{{ detail.principalRegInfo.phoneNumber }}</span>
              </li>
              <li>
                <b>轮次:</b>
                <span> {{ detail.rounds }}</span>
              </li>
              <li>
                <b>经营状态:</b>
                <span>{{ detail.principalRegInfo.regStatus }}</span>
              </li>
            </ul>
            <el-button @click="openPartnerList" type="primary" size="mini">{{
              partnerListShowText
            }}</el-button>
            <div class="plf" v-show="partnerListShow">
              <div class="pl" v-if="partnerList">
                <el-table border :data="partnerList">
                  <el-table-column
                    label="股东名称"
                    prop="PartnerInfo.Name"
                  ></el-table-column>

                  <el-table-column
                    label="持股比例"
                    prop="StockPercent"
                  ></el-table-column>
                  <el-table-column
                    label="认缴出资方式"
                    prop="ShouldType"
                  ></el-table-column>
                  <el-table-column
                    label="认缴出资额（万元）"
                    prop="ShouldCapi"
                  ></el-table-column>
                  <el-table-column
                    label="认缴出资日期"
                    prop="ShoudDate"
                  ></el-table-column>
                  <el-table-column
                    label="认缴公示日期"
                    prop="ShouldPublicDate"
                  ></el-table-column>
                  <el-table-column
                    label="实缴出资方式"
                    prop="RealType"
                  ></el-table-column>
                  <el-table-column
                    label="实缴出资额（万元）"
                    prop="RealCapi"
                  ></el-table-column>
                  <el-table-column
                    label="实缴出资日期"
                    prop="RealDate"
                  ></el-table-column>
                  <el-table-column
                    label="实缴公示日期"
                    prop="ReallyPublicDate"
                  ></el-table-column>
                </el-table>
                <div class="page-info-class">
                  <el-pagination
                    @size-change="handleSizeChangePartner"
                    @current-change="handleCurrentChangePartner"
                    :current-page="this.queryParamsPartnerList.pageNum"
                    :page-size="this.queryParamsPartnerList.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="this.queryParamsPartnerList.total"
                  ></el-pagination>
                </div>
              </div>
              <div v-else class="b">
                <el-empty name="empty" description="暂无股东数据"></el-empty>
              </div>
            </div>
          </template>
          <template v-else-if="dataAuth.principalRegInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply :info="dataAuthInfo.principalRegInfo" />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目经营信息" name="projectOperation">
          <template v-if="dataAuth.businessInfo">
            <template slot="title">
              项目经营信息<i class="header-icon el-icon-info"></i>
            </template>
            <div class="other">
              <el-tabs
                type="border-card"
                @tab-click="handleClick"
                v-model="activeName2"
              >
                <el-tab-pane label="网店相关信息" name="first">
                  <ul class="">
                    <li class="flexJfAc onlineShopList">
                      <b>网店：</b>
                      <div
                        style="padding: 0 2px"
                        v-for="(item, index) in detail.onlineShopList"
                        :key="index"
                      >
                        <div
                          class="pointer"
                          v-if="detail.onlineShopList.length == 1"
                          @click="getOnlineShopOne(item)"
                        >
                          {{
                            item.type == 1
                              ? "淘宝"
                              : item.type == 2
                              ? "京东"
                              : ""
                          }}
                          <el-button size="mini">{{ item.name }}</el-button>
                        </div>
                        <div
                          v-else
                          @click="getOnlineShopOne(item)"
                          class="pointer"
                        >
                          <span class="forIndex">{{ index + 1 }}</span
                          >.{{
                            item.type == 1
                              ? "淘宝"
                              : item.type == 2
                              ? "京东"
                              : ""
                          }}
                          <el-button size="mini">{{ item.name }}</el-button>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div
                    class="a"
                    v-if="tableData != null && onlineShopOneType == 1"
                  >
                    <!-- 表格 -->
                    <el-table border :data="tableData">
                      <el-table-column label="商品标题" prop="title">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.url"
                            target="_blank"
                            :underline="false"
                            >{{ scope.row.title }}</el-link
                          >
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="原价"
                        prop="original_price"
                        width="110"
                      ></el-table-column>
                      <el-table-column
                        label="现价"
                        prop="price"
                        width="110"
                      ></el-table-column>
                      <el-table-column
                        label="销量"
                        prop="sold"
                        width="110"
                      ></el-table-column>
                      <el-table-column label="商品图" prop="img" width="100">
                        <template slot-scope="scope">
                          <img class="picImg" :src="scope.row.img" alt="" />
                        </template>
                      </el-table-column>
                      <el-table-column label="商品链接" prop="url" width="100">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.url"
                            target="_blank"
                            :underline="false"
                            >商品链接</el-link
                          >
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="操作" width="125">
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="openGoodsFeedbackList(scope.row)"
                        >查看商品评价</el-button
                      >
                    </template>
                  </el-table-column> -->
                    </el-table>

                    <div class="page-info-class">
                      <el-pagination
                        @size-change="handleSizeChangeProductList"
                        @current-change="handleCurrentChangeProductList"
                        :current-page="this.queryParamsShoProducts.pageNum"
                        :page-sizes="[10, 20]"
                        :page-size="this.queryParamsShoProducts.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.queryParamsShoProducts.total"
                      ></el-pagination>
                    </div>
                  </div>
                  <div
                    class="a"
                    v-else-if="tableData != null && onlineShopOneType == 2"
                  >
                    <!-- 表格 -->
                    <el-table border :data="tableData">
                      <el-table-column label="商品标题" prop="warename">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.toItemLink"
                            target="_blank"
                            :underline="false"
                            >{{ scope.row.Content.warename }}</el-link
                          >
                        </template>
                      </el-table-column>
                      <el-table-column label="属性" prop="CustomAttrList">
                        <template slot-scope="scope">
                          {{ scope.row.Content.CustomAttrList }}
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="颜色" prop="color">
              <template slot-scope="scope">
                {{ scope.row.Content.color }}
              </template>
            </el-table-column> -->
                      <el-table-column
                        label="价格"
                        prop="dredisprice"
                        width="110"
                      ></el-table-column>
                      <!-- <el-table-column
              label="现价"
              prop="price"
              width="110"
            ></el-table-column> -->
                      <!-- <el-table-column
              label="销量"
              prop="sold"
              width="110"
            ></el-table-column> -->
                      <el-table-column label="商品图" prop="img" width="100">
                        <template slot-scope="scope">
                          <img
                            class="picImg"
                            :src="scope.row.Content.imageurl"
                            alt=""
                          />
                        </template>
                      </el-table-column>
                      <el-table-column label="商品链接" prop="url" width="100">
                        <template slot-scope="scope">
                          <el-link
                            :href="scope.row.toItemLink"
                            target="_blank"
                            :underline="false"
                            >商品链接</el-link
                          >
                        </template>
                      </el-table-column>
                      <!-- <el-table-column label="操作" width="125">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openGoodsFeedbackList(scope.row)"
                  >查看商品评价</el-button
                >
              </template>
            </el-table-column> -->
                    </el-table>

                    <div class="page-info-class">
                      <el-pagination
                        @size-change="handleSizeChangeProductList"
                        @current-change="handleCurrentChangeProductList"
                        :current-page="this.queryParamsShoProducts.pageNum"
                        :page-sizes="[10, 20]"
                        :page-size="this.queryParamsShoProducts.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.queryParamsShoProducts.total"
                      ></el-pagination>
                    </div>
                  </div>
                  <div v-else class="b">
                    <el-empty name="empty" description="暂无数据"></el-empty>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="新闻舆情" name="second">
                  <el-table border :data="newList">
                    <el-table-column
                      label="来源"
                      prop="Source"
                      width="70"
                    ></el-table-column>
                    <el-table-column label="新闻标题" prop="Title">
                      <template slot-scope="scope">
                        <!-- <el-popover
                      placement="top-start"
                      title="描述"
                      width="400"
                      trigger="hover"
                      :content="scope.row.Title"
                    >

                    </el-popover> -->
                        <el-link
                          :href="scope.row.Url"
                          target="_blank"
                          :underline="false"
                        >
                          <span class="beyondDisplay1">
                            {{ scope.row.Title }}
                          </span></el-link
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="新闻标签"
                      prop="NewsTags"
                    ></el-table-column>
                    <el-table-column
                      label="类别"
                      prop="Category"
                    ></el-table-column>
                    <el-table-column
                      label="发布时间"
                      prop="PublishTime"
                      width="100"
                    ></el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChangeNews"
                      @current-change="handleCurrentChangeNews"
                      :current-page="this.queryParamsNewList.pageNum"
                      :page-size="this.queryParamsNewList.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsNewList.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="竞品信息" name="third">
                  <el-table border :data="productList">
                    <el-table-column
                      label="名称"
                      prop="ProductName"
                    ></el-table-column>
                    <el-table-column
                      label="描述"
                      prop="Description"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-popover
                          placement="top-start"
                          title="描述"
                          width="400"
                          trigger="hover"
                          :content="scope.row.Description"
                        >
                          <span slot="reference" class="beyondDisplay1">
                            {{ scope.row.Description }}
                          </span>
                        </el-popover>
                      </template>
                    </el-table-column>

                    <el-table-column
                      label="区域"
                      prop="ProvinceDesc"
                    ></el-table-column>
                    <el-table-column
                      label="成立时间"
                      prop="StartDate"
                    ></el-table-column>
                    <el-table-column
                      label="融资轮次"
                      prop="Round"
                    ></el-table-column>
                    <el-table-column
                      label="最近融资时间"
                      prop="RoundDate"
                    ></el-table-column>
                    <el-table-column
                      label="最近融资金额"
                      prop="Amount"
                    ></el-table-column>
                    <el-table-column label="logo" prop="createDate" width="160">
                      <template slot-scope="scope">
                        <img
                          style="width: 70px"
                          :src="scope.row.ImageUrl"
                          alt=""
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="fileList"
                      width="130"
                      align="left"
                      label="任务操作"
                      fixed="right"
                    >
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          round
                          type="primary"
                          @click="openCompetitor(scope.row)"
                        >
                          查看竞品信息
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="this.queryParamsProduct.pageNum"
                      :page-size="this.queryParamsProduct.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsProduct.total"
                    ></el-pagination>
                  </div>

                  <el-table border :data="productRecommenlist">
                    <el-table-column
                      label="名称"
                      prop="ProductName"
                    ></el-table-column>
                    <el-table-column
                      label="描述"
                      prop="Description"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <el-popover
                          placement="top-start"
                          title="描述"
                          width="400"
                          trigger="hover"
                          :content="scope.row.Description"
                        >
                          <span slot="reference" class="beyondDisplay1">
                            {{ scope.row.Description }}
                          </span>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="区域"
                      prop="RelatedComInfo.Name"
                    ></el-table-column>
                    <el-table-column
                      label="区域"
                      prop="Location"
                    ></el-table-column>
                    <el-table-column
                      label="成立时间"
                      prop="StartDate"
                    ></el-table-column>
                    <el-table-column
                      label="融资轮次"
                      prop="RoundDesc"
                    ></el-table-column>
                    <!-- <el-table-column
                  prop="fileList"
                  width="130"
                  align="left"
                  label="任务操作"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      round
                      type="primary"
                      @click="openCompetitor(scope.row)"
                    >
                      查看竞品信息
                    </el-button>
                  </template>
                </el-table-column> -->
                  </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane label="股东信息" name="fourth">
              <el-table border :data="partnerList">
                <el-table-column
                  label="股东名称"
                  prop="PartnerInfo.Name"
                ></el-table-column>

                <el-table-column
                  label="持股比例"
                  prop="StockPercent"
                ></el-table-column>
                <el-table-column
                  label="认缴出资方式"
                  prop="ShouldType"
                ></el-table-column>
                <el-table-column
                  label="认缴出资额（万元）"
                  prop="ShouldCapi"
                ></el-table-column>
                <el-table-column
                  label="认缴出资日期"
                  prop="ShoudDate"
                ></el-table-column>
                <el-table-column
                  label="认缴公示日期"
                  prop="ShouldPublicDate"
                ></el-table-column>
                <el-table-column
                  label="实缴出资方式"
                  prop="RealType"
                ></el-table-column>
                <el-table-column
                  label="实缴出资额（万元）"
                  prop="RealCapi"
                ></el-table-column>
                <el-table-column
                  label="实缴出资日期"
                  prop="RealDate"
                ></el-table-column>
                <el-table-column
                  label="实缴公示日期"
                  prop="ReallyPublicDate"
                ></el-table-column>
              </el-table>
              <div class="page-info-class">
                <el-pagination
                  @size-change="handleSizeChangePartner"
                  @current-change="handleCurrentChangePartner"
                  :current-page="this.queryParamsPartnerList.pageNum"
                  :page-size="this.queryParamsPartnerList.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.queryParamsPartnerList.total"
                ></el-pagination>
              </div>
            </el-tab-pane> -->
                <el-tab-pane label="招聘信息" name="fifth">
                  <el-table border :data="recruitmentList">
                    <el-table-column
                      label="企业名称"
                      prop="CompanyName"
                    ></el-table-column>
                    <el-table-column
                      label="职位"
                      prop="Title"
                    ></el-table-column>
                    <el-table-column
                      label="学历"
                      prop="Education"
                    ></el-table-column>
                    <el-table-column
                      label="经验"
                      prop="Experience"
                    ></el-table-column>

                    <el-table-column
                      label="发布日期"
                      prop="PublishDate"
                    ></el-table-column>
                    <el-table-column
                      label="区域"
                      prop="ProvinceDesc"
                    ></el-table-column>
                  </el-table>
                  <div class="page-info-class">
                    <el-pagination
                      @size-change="handleSizeChangeRecruitment"
                      @current-change="handleCurrentChangeRecruitment"
                      :current-page="this.queryParamsRecruitment.pageNum"
                      :page-size="this.queryParamsRecruitment.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.queryParamsRecruitment.total"
                    ></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="百度资讯" name="baidu"> </el-tab-pane>
                <el-tab-pane label="微博资讯" name="weibo">
                  <div class="baidu" v-if="iframeUrlWeibo">
                    <iframe
                      ref="weibo"
                      :src="iframeUrlWeibo"
                      frameborder="0"
                      style="width: 100%; height: 75vh"
                    ></iframe>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="企查查" name="qcc">
                  <div class="baidu" v-if="iframeUrlQcc">
                    <iframe
                      ref="qcc"
                      :src="iframeUrlQcc"
                      frameborder="0"
                      style="width: 100%; height: 75vh"
                    ></iframe>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="抖音" name="tiktok">
                  <tiktok
                    v-if="tiktokKeyword"
                    :type="null"
                    :searchKeywords="tiktokKeyword"
                  ></tiktok>
                </el-tab-pane>
              </el-tabs>
            </div>

            <!-- <el-divider content-position="center">项目数据</el-divider>
    <el-collapse @change="collapseChange" v-show="detail">
      <el-collapse-item>
        <template slot="title">
          项目数据<i class="header-icon el-icon-document"></i>
        </template>

      </el-collapse-item>
    </el-collapse> -->
          </template>
          <template v-else-if="dataAuth.businessInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply :info="dataAuthInfo.businessInfo" />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="联系人信息">
          <template v-if="dataAuth.contactInfo">
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>项目BD:</b>
                <span> {{ detail.initialOwnerName }}</span>
              </li>
              <li>
                <b>联系人：</b>
                <span
                  >{{ detail.contactName }}
                  <span v-show="detail.contactName">{{
                    detail.contactGender == 0
                      ? "女士"
                      : detail.contactGender == 1
                      ? "先生"
                      : ""
                  }}</span>
                </span>
              </li>
              <li>
                <b>电话：</b>
                <span>{{ detail.contactPhone }}</span>
              </li>
              <li>
                <b>职位：</b>
                <span>{{ detail.contactPosition }}</span>
              </li>

              <li>
                <b>微信：</b>
                <span>{{ detail.contactWechat }}</span>
              </li>
              <li>
                <b>E-Mail：</b>
                <span>{{ detail.contactMail }}</span>
              </li>
            </ul>
          </template>
          <template v-else-if="dataAuth.contactInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply :info="dataAuthInfo.contactInfo" />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目文件" name="documentList">
          <template v-if="dataAuth.documentFile || dataAuth.dingTalkFile">
            <template slot="title">
              项目文件<i class="header-icon el-icon-document"></i>
            </template>
            <documentList
              v-if="documentListShow"
              :type="1"
              :projectInfo="detail2"
              :dataAuth="dataAuth"
              :dataAuthInfo="dataAuthInfo"
            ></documentList>
          </template>
          <template
            v-else-if="
              dataAuth.documentFile === false && dataAuth.dingTalkFile === false
            "
          >
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  v-if="dataAuth.documentFile === false"
                  title="暂无权限查看项目文件"
                  :info="dataAuthInfo.documentFile"
                />
                <data-auth-apply
                  v-if="dataAuth.dingTalkFile === false"
                  title="暂无权限查看网盘文件"
                  :info="dataAuthInfo.dingTalkFile"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="历史操作">
          <div class="other">
            <el-tabs type="border-card" v-model="activeName">
              <el-tab-pane label="项目评估历史" name="first">
                <template v-if="dataAuth.evaluationInfo">
                  <el-table
                    border
                    :data="detail.evaluationRecordList"
                    :span-method="objectSpanMethod"
                  >
                    <el-table-column
                      label="业务流程"
                      prop="projectWorkflowName"
                    ></el-table-column>
                    <el-table-column
                      label="模版"
                      prop="templateName"
                    ></el-table-column>
                    <el-table-column
                      label="流程节点"
                      prop="workflowNodeName"
                    ></el-table-column>
                    <el-table-column
                      label="评估人"
                      prop="evaluatorName"
                    ></el-table-column>
                    <el-table-column
                      label="评估时间"
                      prop="createDate"
                      width="160"
                    >
                      <template slot-scope="scope">
                        <span>{{
                          scope.row.createDate | formatDateFilter
                        }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="fileList"
                      width="180"
                      align="left"
                      label="任务操作"
                    >
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          round
                          @click="openAssessment(scope.row)"
                        >
                          打开评估
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
                <template v-else-if="dataAuth.evaluationInfo === false">
                  <el-empty :image-size="50">
                    <template slot="description">
                      <data-auth-apply :info="dataAuthInfo.evaluationInfo" />
                    </template>
                  </el-empty>
                </template>
              </el-tab-pane>
              <el-tab-pane label="项目会议历史" name="second">
                <!--          <template v-if="dataAuth.meetingList">-->
                <el-table
                  border
                  :data="detail.meetingList"
                  :span-method="objectSpanMethod"
                >
                  <el-table-column
                    label="业务流程"
                    prop="projectWorkflowName"
                  ></el-table-column>
                  <el-table-column
                    label="会议主题"
                    prop="subject"
                  ></el-table-column>
                  <el-table-column
                    label="会议日期"
                    prop="createDate"
                    width="160"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.beginTime | formatDateFilter }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="与会外部人员"
                    prop="externalUsers"
                    width="110"
                  ></el-table-column>
                  <el-table-column label="内部人员" prop="place">
                    <template slot-scope="scope">
                      <span
                        class="span-tag-list"
                        v-for="item in scope.row.internalUserList"
                        :key="item.id"
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.realName"
                          placement="top"
                        >
                          <span
                            >{{ item.realName }}<span class="d">,</span>
                          </span>
                        </el-tooltip>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="待办事项" prop="place">
                    <template slot-scope="scope">
                      <span
                        class="span-tag-list"
                        v-for="(item, index) in scope.row.matterList"
                        :key="item.id"
                      >
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.content"
                          placement="top"
                        >
                          <span
                            ><span class="forIndex">{{ index + 1 }}</span
                            >.{{ item.content }}
                          </span>
                        </el-tooltip>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="会议内容" prop="content">
                    <template slot-scope="scope">
                      <el-button
                        @click="openContent(scope.row)"
                        slot="reference"
                        >会议内容</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <!--          </template>-->
                <template v-if="dataAuth.meetingList === false">
                  <el-empty :image-size="50">
                    <template slot="description">
                      <data-auth-apply :info="dataAuthInfo.meetingList" />
                    </template>
                  </el-empty>
                </template>
              </el-tab-pane>
              <el-tab-pane label="项目任务进展" name="fourth">
                <template v-if="dataAuth.taskInfo">
                  <div
                    class=""
                    v-for="(item, index) in detail.workflowTaskList"
                    :key="index"
                  >
                    <h3>
                      <span class="forIndex">{{ index + 1 }}</span
                      >.{{ item.name }}
                    </h3>
                    <el-table
                      border
                      row-key="id"
                      style="width: 100%"
                      :data="item.taskList"
                    >
                      <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->
                      <el-table-column
                        label="流程节点"
                        prop="nodeName"
                      ></el-table-column>
                      <el-table-column
                        label="任务"
                        prop="name"
                        width="200"
                      ></el-table-column>
                      <el-table-column
                        label="负责人"
                        prop="ownerName"
                      ></el-table-column>

                      <el-table-column
                        label="任务状态"
                        prop="status"
                        width="65"
                      >
                        <template slot-scope="scope">
                          <div
                            :class="[
                              scope.row.status == 1
                                ? 'status1'
                                : scope.row.status == 2
                                ? 'status2'
                                : scope.row.status == 3
                                ? 'status3'
                                : scope.row.status == 4
                                ? 'status4'
                                : scope.row.status == 5
                                ? 'status5'
                                : '',
                            ]"
                          >
                            {{
                              scope.row.status == 1
                                ? "待启动"
                                : scope.row.status == 2
                                ? "进行中"
                                : scope.row.status == 3
                                ? "已完成"
                                : scope.row.status == 4
                                ? "已取消"
                                : scope.row.status == 5
                                ? "已暂停"
                                : "未知"
                            }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="进度" prop="progress">
                        <template slot-scope="scope">
                          <div>{{ scope.row.progress }}%</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="fileList"
                        width="165"
                        align="center"
                        label="文件"
                      >
                        <template slot-scope="scope">
                          <el-link
                            style="cursor: pointer"
                            v-for="(item, index) in scope.row.fileList"
                            :key="index"
                            @click="handleExport(item)"
                          >
                            <p v-if="item.originalFileName">
                              <span class="forIndex">{{ index + 1 }}</span
                              >.{{ item.originalFileName }}
                              <span
                                v-if="dataAuth.documentFile === false"
                                class="no-permission"
                                >暂无权限查看</span
                              >
                            </p>
                            <p v-else>
                              <span class="forIndex">{{ index + 1 }}</span
                              >.{{ item.name }}
                              <span
                                v-if="dataAuth.documentFile === false"
                                class="no-permission"
                                >暂无权限查看</span
                              >
                            </p>
                          </el-link>
                        </template>
                      </el-table-column>
                      <!-- <el-table-column
                label="风险及应对"
                prop="remark"
              ></el-table-column> -->
                      <el-table-column
                        label="计划开始日期"
                        prop="createDate"
                        width="155"
                      >
                        <template slot-scope="scope">
                          <span v-show="scope.row.planBeginTime">{{
                            scope.row.planBeginTime | formatDateFilter
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="计划结束日期"
                        prop="createDate"
                        width="155"
                      >
                        <template slot-scope="scope">
                          <span>{{
                            scope.row.planEndTime | formatDateFilter
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                          <el-button
                            type="primary"
                            size="mini"
                            @click="openTaskCommen(scope.row)"
                            >评论
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
                <template v-else-if="dataAuth.taskInfo === false">
                  <el-empty :image-size="50">
                    <template slot="description">
                      <data-auth-apply :info="dataAuthInfo.taskInfo" />
                    </template>
                  </el-empty>
                </template>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-tab-pane>
        <el-tab-pane label="评论" name="comment">
          <div class="comment newComment" v-if="projectId && commentLoading">
            <comment
              :subjectType="subjectType"
              :subjectId="projectId"
              :commentLoading="commentLoading"
            ></comment>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      title="会议内容"
      :visible.sync="meetingContentDialog"
      width="60%"
      :before-close="handleMeetingContentClose"
      append-to-body
    >
      <span v-html="meetingContent">{{ meetingContent }}</span>
      <!-- 评论 -->
      <el-divider content-position="center">评论</el-divider>
      <div class="comment" v-if="commentMeetingId">
        <comment
          :subjectType="commentMeetingType"
          :subjectId="commentMeetingId"
        ></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="meetingContentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="meetingContentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="预览"
      append-to-body
      center
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="pdfUrlF" v-if="pdfUrl"></div>
      <div class="imgUrl" v-if="imgUrl">
        <el-image ref="imgUrl" :src="imgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 任务评论 -->
    <el-dialog
      title="任务评论"
      :visible.sync="taskCommentDialog"
      width="60%"
      :before-close="taskContentClose"
      append-to-body
    >
      <!-- 评论 -->
      <!-- <el-divider content-position="center">评论</el-divider> -->
      <div class="comment" v-if="taskCommentDialog">
        <comment :subjectType="taskSubjectType" :subjectId="taskId"></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="taskCommentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="taskCommentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../common/date";
import {
  findProjectAllDetail,
  followProject,
  unFollowProject,
} from "@/api/projectManage";
import documentList from "@/components/myMission/projectDocuments/documentList.vue";
import { getExternalData } from "@/api/externalData";
import comment from "@/components/comment/comment.vue";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";
import tiktok from "@/components/onlineShopGoods/tiktok.vue";

export default {
  name: "detailsDialog",
  props: {
    msPd: {
      type: Object,
      default: () => {},
    },
    projectInfo: {
      type: Object,
      default: () => {},
    },

    proId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      projectId: null,
      queryParams: {},
      detail: {},
      detail2: {},
      activeName: "first",
      activeName2: "first",
      detailActiveName: null,
      loading: false,
      meetingContent: null,
      meetingContentDialog: false,
      dialogVisible: false,
      pdfUrl: null,
      imgUrl: null,
      scale: 100,
      documentListShow: false,
      queryParamsProduct: {
        providerName: "qcc.co.product-list",
        searchKey: null,
        pageNum: 1,
        pageSize: 50,
      },
      productList: [],
      queryParamsProductRecommen: {
        providerName: "qcc.co.product-recommend-list",
        id: null,
      },
      productRecommenlist: [],
      queryParamsRecruitment: {
        providerName: "qcc.co.recruitment-list",
        searchKey: null,
        pageNum: 1,
        pageSize: 50,
      },
      recruitmentList: [],
      queryParamsNewList: {
        providerName: "qcc.co.news-list",
        searchKey: null,
        emotionType: null,
        category: null,
        startDate: null,
        endDate: null,
        pageNum: 1,
        pageSize: 50,
      },
      newList: [],
      queryParamsPartnerList: {
        providerName: "qcc.co.partner-list",
        searchKey: null,
        pageNum: 1,
        pageSize: 50,
      },
      partnerList: null,
      queryParamsShoProducts: {
        startPrice: null,
        endPrice: null,
        keywords: "",
        providerName: "tb.shop.goods-list",
        shopId: null,
        sort: 3,
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      tableData: [],
      onlineShopOneType: null,
      partnerListShow: false,
      partnerListShowText: "查看股东信息",
      dataAuth: {},
      dataAuthInfo: {},
      subjectType: "PROJECT_DETAIL",
      commentMeetingType: "MEETING_DETAIL",
      commentMeetingId: null,
      taskId: null,
      taskSubjectType: "TASK_DETAIL",
      taskCommentDialog: false,
      principalRegInfoNameLink: null,
      iframeUrlWeibo: null,
      iframeUrlQcc: null,
      commentLoading: false,
      tabName: null,
      tiktokKeyword: null,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  computed: {},
  watch: {
    proId: {
      handler(newVal, old) {
        if (newVal) {
          this.projectId = JSON.parse(JSON.stringify(newVal));
          this.findProjectAllDetail();
        }
      },
      immediate: true,
      deep: true,
    },

    queryParams: {
      handler(newVal, old) {
        if (newVal) {
          // this.findProjectAllDetail();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    DataAuthApply,
    documentList,
    comment,
    tiktok,
  },
  mounted() {
    // if (this.$route.query) {
    //   this.projectId = JSON.parse(JSON.stringify(this.$route.query.projectId));
    //   this.findProjectAllDetail();
    // }
  },
  activated() {
    if (this.$route.query) {
      this.projectId = JSON.parse(JSON.stringify(this.$route.query.projectId));
      this.queryParams = JSON.parse(JSON.stringify(this.$route.params));
      this.findProjectAllDetail();

      this.dataAuthInfo = {
        baseInfo: {
          event: "base_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        businessInfo: {
          event: "business_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        principalRegInfo: {
          event: "principal_reg_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        documentFile: {
          event: "document_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        dingTalkFile: {
          event: "ding_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        taskInfo: {
          event: "task_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        contactInfo: {
          event: "contact_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        meetingList: {
          event: "meeting_list",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        evaluationInfo: {
          event: "evaluation_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
      };
    }
  },
  methods: {
    tabHandleClick(tab, event) {
      console.log(tab.index);
      console.log(tab);
      let index = tab.index;
      if (tab.name == "documentList") {
        this.documentListShow = true;
      } else if (tab.name == "comment") {
        this.commentLoading = false;
        setTimeout(() => {
          this.commentLoading = true;
        }, 200);
      }
      //
    },
    changeProject(type) {
      if (type == "previous") {
        if (this.detail.navButton.prevId != null) {
          this.projectId = JSON.parse(
            JSON.stringify(this.detail.navButton.prevId)
          );
          this.findProjectAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有上一个!" });
        }
      } else {
        if (this.detail.navButton.nextId != null) {
          this.projectId = JSON.parse(
            JSON.stringify(this.detail.navButton.nextId)
          );
          this.findProjectAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有下一个!" });
        }
      }
    },
    followProject() {
      let that = this;
      let title = that.detail.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project",
        refId: that.detail.id,
      };
      this.$confirm(
        "确认" + title + "项目 [" + that.detail.name + "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (that.detail.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "关注成功!" });
                that.findProjectAllDetail();
              } else {
                that.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (that.detail.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "取消关注成功!" });
                that.findProjectAllDetail();
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    openTaskCommen(row) {
      console.log(row);
      this.taskId = row.id;
      this.taskCommentDialog = true;
    },
    taskContentClose() {
      this.taskCommentDialog = false;
    },
    openPartnerList() {
      if (this.partnerListShow) {
        this.partnerListShowText = "查看股东信息";
        this.partnerListShow = !this.partnerListShow;
      } else {
        this.partnerListShowText = "关闭股东信息";
        this.queryParamsPartnerList.searchKey = this.detail.principalName;
        this.getExternaPartnerList(1, this.queryParamsPartnerList.pageSize);
        this.partnerListShow = true;
      }
    },
    handleClick(tab, event) {
      let that = this;
      console.log(tab.index);
      let index = tab.index;
      this.tabName = tab.name;
      if (index == 0) {
      } else if (index == 1) {
        this.queryParamsNewList.searchKey = this.detail.principalName;
        this.getExternaNewsList(1, this.queryParamsNewList.pageSize);
      } else if (index == 2) {
        this.queryParamsProduct.searchKey = this.detail.principalName;
        this.getExternalData(1, this.queryParamsProduct.pageSize);
      } else if (index == 3) {
        // this.queryParamsPartnerList.searchKey = this.detail.principalName;
        // this.getExternaPartnerList(1, this.queryParamsPartnerList.pageSize);
        this.queryParamsRecruitment.searchKey = this.detail.principalName;
        this.getExternalRecruitmentList(
          1,
          this.queryParamsRecruitment.pageSize
        );
      } else if (index == 4) {
        // this.queryParamsRecruitment.searchKey = this.detail.principalName;
        // this.getExternalRecruitmentList(
        //   1,
        //   this.queryParamsRecruitment.pageSize
        // );
      }
      if (tab.name == "baidu") {
        if (
          that.detail.searchKeywords == "" ||
          that.detail.searchKeywords == null ||
          that.detail.searchKeywords == undefined
        ) {
          window.open(
            "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
              this.detail.principalName,
            "baidu"
          );
        } else {
          let searchKeywords = JSON.parse(
            JSON.stringify(this.detail.searchKeywords)
          );
          if (!Array.isArray(searchKeywords)) {
            searchKeywords = searchKeywords.replace(/,/g, " ");
          } else {
            searchKeywords = searchKeywords.join(" ");
          }
          window.open(
            "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
              searchKeywords,
            "baidu"
          );
        }
      } else if (tab.name == "qcc") {
        window.open(
          "https://www.qcc.com/web/search?key=" + this.detail.principalName,
          "qcc"
        );
      }
    },
    getOnlineShopOne(data) {
      console.log(data);
      this.tableData = null;
      this.onlineShopOneType = data.type;
      this.queryParamsShoProducts.shopId = data.originalId;
      this.getExternalDataProductList(1, this.queryParamsShoProducts.pageSize);
    },
    getExternalDataProductList(num, pageSize) {
      if (this.onlineShopOneType == 1) {
        this.queryParamsShoProducts.providerName = "tb.shop.goods-list";
      } else if (this.onlineShopOneType == 2) {
        this.queryParamsShoProducts.providerName = "jd.shop.goods-list";
      }
      this.queryParamsShoProducts.pageNum = num;
      this.queryParamsShoProducts.pageSize = pageSize;
      // this.queryParamsShoProducts.shopId = this.detail.id;
      if (this.onlineShopOneType == 1) {
        getExternalData(this.queryParamsShoProducts).then((response) => {
          // console.log(this.tableData);
          if (response.data.success) {
            let tableData = response.data.nodes;
            tableData.forEach((item, index) => {
              if (item.img.indexOf("http") || item.img.indexOf("https")) {
                item.img = "https:" + item.img;
              }
              if (item.url.indexOf("http") || item.url.indexOf("https")) {
                item.url = "https:" + item.url;
              }
            });
            this.tableData = tableData;
            this.queryParamsShoProducts.pageNum = response.data.current_page;
            this.queryParamsShoProducts.pageSize = response.data.page_size;
            this.queryParamsShoProducts.total = response.data.total_results;
            this.queryParamsShoProducts.pageCount = response.data.total_page;
          } else {
            this.tableData = null;
          }
        });
      } else if (this.onlineShopOneType == 2) {
        getExternalData(this.queryParamsShoProducts).then((response) => {
          // console.log(this.tableData);
          if (response.success) {
            let tableData = response.data.Paragraph;
            let page = response.data.Summary;
            tableData.forEach((item, index) => {
              if (
                item.Content.imageurl &&
                (item.Content.imageurl.indexOf("http") ||
                  item.Content.imageurl.indexOf("https"))
              ) {
                item.Content.imageurl =
                  "https://img13.360buyimg.com/imgzone/" +
                  item.Content.imageurl;
              }
            });
            this.tableData = tableData;
            this.queryParamsShoProducts.pageNum = Number(page.Page.PageIndex);
            this.queryParamsShoProducts.pageSize = Number(page.Page.PageSize);
            this.queryParamsShoProducts.total = Number(page.ResultCount);
            this.queryParamsShoProducts.pageCount = Number(page.Page.PageCount);
          } else {
            this.tableData = null;
          }
        });
      }
    },
    getExternaPartnerList(pageNum, pageSize) {
      this.queryParamsPartnerList.pageNum = pageNum;
      this.queryParamsPartnerList.pageSize = pageSize;
      getExternalData(this.queryParamsPartnerList).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.partnerList = tableData;
          this.queryParamsPartnerList.pageNum = Number(response.data.PageIndex);
          this.queryParamsPartnerList.pageSize = Number(response.data.PageSize);
          this.queryParamsPartnerList.total = Number(
            response.data.TotalRecords
          );

          // this.queryParamsNewList.pageCount = Number(response.data.totalPage);
        } else {
          this.partnerList = null;
          this.queryParamsPartnerList.total = 0;
        }
      });
    },
    getExternaNewsList(pageNum, pageSize) {
      this.queryParamsNewList.pageNum = pageNum;
      this.queryParamsNewList.pageSize = pageSize;
      getExternalData(this.queryParamsNewList).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.newList = tableData;
          this.queryParamsNewList.pageNum = Number(response.data.PageIndex);
          this.queryParamsNewList.pageSize = Number(response.data.PageSize);
          this.queryParamsNewList.total = Number(response.data.TotalRecords);
          // this.queryParamsNewList.pageCount = Number(response.data.totalPage);
        } else {
          this.newList = null;
        }
      });
    },
    getExternalRecruitmentList(pageNum, pageSize) {
      this.queryParamsRecruitment.pageNum = pageNum;
      this.queryParamsRecruitment.pageSize = pageSize;
      getExternalData(this.queryParamsRecruitment).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.recruitmentList = tableData;
          this.queryParamsRecruitment.pageNum = Number(response.data.PageIndex);
          this.queryParamsRecruitment.pageSize = Number(response.data.PageSize);
          this.queryParamsRecruitment.total = Number(
            response.data.TotalRecords
          );
          // this.queryParamsRecruitment.pageCount = Number(response.data.totalPage);
        } else {
          this.recruitmentList = null;
          this.queryParamsRecruitment.total = 0;
        }
      });
    },
    handleSizeChangeProductList(val) {
      console.log(val);
      this.getExternalDataProductList(1, val);
    },
    handleCurrentChangeProductList(val) {
      console.log(val);
      if (val > this.queryParamsShoProducts.pageCount) {
        val = this.queryParamsShoProducts.pageCount;
      }
      this.getExternalDataProductList(
        val,
        this.queryParamsShoProducts.pageSize
      );
    },
    handleSizeChangeNews(val) {
      console.log(val);
      this.getExternaNewsList(1, val);
    },
    handleCurrentChangeNews(val) {
      console.log(val);
      if (val > this.queryParamsNewList.pageCount) {
        val = this.queryParamsNewList.pageCount;
      }
      this.getExternaNewsList(val, this.queryParamsNewList.pageSize);
    },
    handleSizeChangePartner(val) {
      console.log(val);
      this.getExternaNewsList(1, val);
    },
    handleCurrentChangePartner(val) {
      console.log(val);
      if (val > this.queryParamsNewList.pageCount) {
        val = this.queryParamsNewList.pageCount;
      }
      this.getExternaNewsList(val, this.queryParamsNewList.pageSize);
    },
    handleSizeChangeRecruitment(val) {
      console.log(val);
      this.getExternalRecruitmentList(1, val);
    },
    handleCurrentChangeRecruitment(val) {
      console.log(val);
      if (val > this.queryParamsRecruitment.pageCount) {
        val = this.queryParamsRecruitment.pageCount;
      }
      this.getExternalRecruitmentList(
        val,
        this.queryParamsRecruitment.pageSize
      );
    },
    handleSizeChange(val) {
      console.log(val);
      this.getExternalData(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.queryParamsProduct.pageCount) {
        val = this.queryParamsProduct.pageCount;
      }
      this.getExternalData(val, this.queryParamsProduct.pageSize);
    },
    openCompetitor(data) {
      console.log(data);
      this.queryParamsProductRecommen.id = data.ProductId;
      getExternalData(this.queryParamsProductRecommen).then((response) => {
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          this.productRecommenlist = tableData;
          // this.queryParamsProduct.pageNum = Number(response.data.PageIndex);
          // this.queryParamsProduct.pageSize = Number(response.data.PageSize);
          // this.queryParamsProduct.total = Number(response.data.TotalRecords);
          // this.queryParamsProduct.pageCount = Number(response.data.totalPage);
        } else {
          this.productRecommenlist = null;
        }
      });
    },
    getExternalData(pageNum, pageSize) {
      this.queryParamsProduct.pageNum = pageNum;
      this.queryParamsProduct.pageSize = pageSize;
      getExternalData(this.queryParamsProduct).then((response) => {
        // console.log(this.tableData);
        if (response.success && response.data.Data) {
          let tableData = response.data.Data;
          // tableData.forEach((item, index) => {
          //   if (item.img.indexOf("http") || item.img.indexOf("https")) {
          //     item.img = "https:" + item.img;
          //   }
          //   if (item.url.indexOf("http") || item.url.indexOf("https")) {
          //     item.url = "https:" + item.url;
          //   }
          // });
          this.productList = tableData;
          this.queryParamsProduct.pageNum = Number(response.data.PageIndex);
          this.queryParamsProduct.pageSize = Number(response.data.PageSize);
          this.queryParamsProduct.total = Number(response.data.TotalRecords);
          // this.queryParamsProduct.pageCount = Number(response.data.totalPage);
        } else {
          this.productList = null;
        }
      });
    },
    projectOperationChange(array) {
      console.log(array);
      console.log(this.activeName2);
      if (array) {
        this.queryParamsProduct.searchKey = this.detail.principalName;
        this.getExternalData();
      }
    },
    collapseChange(array) {
      console.log(array);
      if (array) {
        this.documentListShow = true;
      }
    },
    openContent(val) {
      this.meetingContent = val.content;
      this.commentMeetingId = val.id;
      this.meetingContentDialog = true;
    },
    handleMeetingContentClose() {
      this.meetingContentDialog = false;
    },

    handleExport(item) {
      //下载文件
      // let userId = window.localStorage.getItem("userId");
      // let memberList = JSON.parse(JSON.stringify(this.detail.memberList));
      // let download = memberList.some((value, index) => value.userId == userId);
      // if (download) {
      //   //下载文件
      //   var elemIF = document.createElement("iframe");
      //   elemIF.src = item.url;
      //   elemIF.style.display = "none";
      //   document.body.appendChild(elemIF);
      // } else {
      //   this.$message({ type: "warning", message: "您没有下载权限!" });
      // }
      if (this.dataAuth.documentFile === false) {
        return;
      }

      let fileType = item.url.split(".").pop();
      console.log(fileType);
      this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: item.url, fileType: fileType },
      });
      return false;
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        console.log(item.url);
        let url = encodeURIComponent(item.url);
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else if (fileType == "pdf") {
        console.log(item.url);
        this.imgUrl = null;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + item.url;
        window.open(url);
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.pdfUrl = null;
        this.imgUrl = item.url;
        this.dialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(item.url);
      }
    },
    openAssessment(row) {
      this.$store.commit("setAssessmentProjectId", row.projectId);
      const { href } = this.$router.resolve({
        name: `myMission`,
        query: {
          projectId: row.projectId,
          projectName: row.projectName,
          projectWorkflowId: row.workflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
        },
      });

      window.open(href);
    },
    findProjectAllDetail() {
      var that = this;
      that.loading = true;

      let info = {
        id: that.projectId,
        ...that.queryParams,
      };
      findProjectAllDetail(info).then((res) => {
        if (res.success) {
          // console.log(res.data);
          let detail = res.data;
          that.detail2 = {
            projectId: res.data.id,
            projectType: res.data.type,
          };
          if (detail.brand != null && !Array.isArray(detail.brand)) {
            detail.brand = detail.brand.split(",");
          }
          if (
            detail.searchKeywords != null &&
            !Array.isArray(detail.searchKeywords)
          ) {
            detail.searchKeywords = detail.searchKeywords.split(",");
          }
          if (detail.principalName) {
            that.principalRegInfoNameLink =
              "https://www.qcc.com/web/search?key=" +
              detail.principalName;

            if (
              detail.searchKeywords == "" ||
              detail.searchKeywords == null ||
              detail.searchKeywords == undefined
            ) {
              that.iframeUrlWeibo =
                "https://s.weibo.com/weibo?q=" +
                detail.principalName;
              that.tiktokKeyword = detail.principalName;
            } else {
              let searchKeywordsWeibo = JSON.parse(
                JSON.stringify(detail.searchKeywords)
              );
              if (!Array.isArray(searchKeywordsWeibo)) {
                searchKeywordsWeibo = searchKeywordsWeibo.replace(/,/g, " ");
              } else {
                searchKeywordsWeibo = searchKeywordsWeibo.join(" ");
              }
              that.iframeUrlWeibo =
                "https://s.weibo.com/weibo?q=" + searchKeywordsWeibo;
              that.tiktokKeyword = searchKeywordsWeibo;
            }
          }
          that.commentLoading = true;
          that.getSpanArr(detail.evaluationRecordList);
          that.loading = false;
          that.dataAuth = res.data.projectAuthResult;
          if (that.detailActiveName == "projectOperation") {
            if (that.tabName == "baidu") {
              if (
                detail.searchKeywords == "" ||
                detail.searchKeywords == null ||
                detail.searchKeywords == undefined
              ) {
                window.open(
                  "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
                    detail.principalName,
                  "baidu"
                );
              } else {
                let searchKeywords = JSON.parse(
                  JSON.stringify(detail.searchKeywords)
                );
                if (!Array.isArray(searchKeywords)) {
                  searchKeywords = searchKeywords.replace(/,/g, " ");
                } else {
                  searchKeywords = searchKeywords.join(" ");
                }
                window.open(
                  "https://www.baidu.com/s?rtt=1&bsst=1&cl=2&tn=news&ie=utf-8&word=" +
                    searchKeywords,
                  "baidu"
                );
              }
            } else if (that.tabName == "qcc") {
              window.open(
                "https://www.qcc.com/web/search?key=" +
                  detail.principalName,
                "qcc"
              );
            }
          }

          that.detail = detail;

        }
      });
    },
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].templateId === data[i - 1].templateId) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.details {
  height: 350px;
  width: 100%;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 100%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 130px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 130px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.picImg {
  width: 60px;
}
.onlineShopList {
  margin-bottom: 10px;
}
.plf {
  margin-top: 10px;
}
.no-permission {
  padding: 0 10px;
  font-size: 12px;
  color: #c0c4cc;
}
.title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
  color: #303133;
}
.name {
  margin-right: 10px;
}
</style>
